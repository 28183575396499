import React from "react";

const BudgetSelect = ({ formData, setFormData }) => {
  const handleBudgetChange = (e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      budget: value,
      customBudget: value === "AUTRES" ? formData.customBudget : "", // Reset customBudget if not "AUTRES"
    });
  };

  const handleCustomBudgetChange = (e) => {
    setFormData({ ...formData, customBudget: e.target.value });
  };

  return (
    <div className="form-group">
      <label>Imputation Budgétaire:</label>
      <select
        id="budget"
        className="form-control"
        value={formData.budget}
        onChange={handleBudgetChange}
      >
        <option value="">-- Sélectionner un budget --</option>
        <option value="BUDGET ARD">BUDGET ARD</option>
        <option value="PACASEN">PACASEN</option>
        <option value="PAIJEF">PAIJEF</option>
        <option value="AGRIJEUNES">AGRIJEUNES</option>
        <option value="SOLTHIS">SOLTHIS</option>
        <option value="PNDL">PNDL</option>
        <option value="AUTRES">AUTRES</option>
      </select>

      {formData.budget === "AUTRES" && (
        <div className="form-group mt-2">
          <label>Budget personnalisé:</label>
          <input
            type="text"
            className="form-control"
            value={formData.customBudget}
            onChange={handleCustomBudgetChange}
            placeholder="Entrez le nom du programme"
          />
        </div>
      )}
    </div>
  );
};

export default BudgetSelect;
