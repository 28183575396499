import React, { useState } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import "./SuiviStagiares.css";

function SuiviStagiares() {
  const [formData, setFormData] = useState({
    name: "",
    sector: "",
    company: "",
    position: "",
    supervisor: "",
    phone: "",
    fonction: "",
    email: "",
    skillsAcquired: false,
    skillsList: "",
    firstExperience: false,
    difficulties: false,
    difficultiesList: "",
    solutions: "",
    hasJobDescription: false,
    tasksMatch: false,
    tasksMismatchReason: "",
    tasks: [],
    workingConditions: false,
    unfavorableReason: "",
    comments: "",
    date: "",
    administrator: "",
    function: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Fonction pour charger une image
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      });
    };

    // Initialize jsPDF
    const doc = new jsPDF();
    Promise.all([
      loadImage("/img/logoArd.png"),
      loadImage("/img/italie.png"),
      loadImage("/img/paijeft.png"),
    ])
      .then(([imgArd, italie, paijeft]) => {
        // Add title
        doc.setFontSize(12);
        doc.addImage(imgArd, "PNG", 15, 5, 30, 20); // Logo ARD
        doc.addImage(italie, "PNG", 90, 5, 30, 20); // Logo ARD
        doc.addImage(paijeft, "PNG", 160, 5, 30, 20); // Logo ARD

        // Add form data to PDF
        let yOffset = 30;
        // const addText = (label, value) => {
        //   doc.setFontSize(10);
        //   doc.text(label, 14, yOffset);
        //   doc.text(value, 100, yOffset);
        //   yOffset += 10;
        // };

        const tableData = [
          ["Prénom et Nom", formData.name],
          ["Secteur d'activité", formData.sector],
          ["Raison sociale de l'Entreprise", formData.company],
          ["Poste occupé", formData.position],
          ["Nom du Maître de Stage", formData.supervisor],
          ["Fonction du Maître de Stage", formData.fonction],
          ["Tél (Téléphone)", formData.phone],
          ["Email", formData.email],
          [
            "Compétences acquises",
            formData.skillsAcquired ? "Oui" : "Non", // Afficher "Oui" ou "Non" selon la réponse
            formData.skillsAcquired && formData.skillsList
              ? formData.skillsList
              : "", // Si "Oui", afficher les compétences, sinon vide
          ],
          [
            "Première expérience dans un domaine lié au stage",
            formData.firstExperience ? "Oui" : "Non",
          ],
          ["Difficultés rencontrées", formData.difficulties ? "Oui" : "Non"],
          [
            "Si oui, lesquelles ?",
            formData.difficulties ? formData.difficultiesList : "",
          ],
          [
            "Comment les avez-vous résolus ?",
            formData.difficulties ? formData.solutions : "",
          ],
          ...(formData.hasJobDescription
            ? [
                [
                  "Avez-vous une description de poste ?",
                  formData.hasJobDescription ? "Oui" : "Non",
                ],
              ]
            : []),
          [
            "Tâches effectuées",
            formData.tasks
              .join("; ") // Joint les tâches séparées par des points-virgules
              .split(";") // Divise à nouveau en un tableau
              .map((task) => task.trim()) // Ajoute un point au début de chaque tâche
              .join("\n"), //Rejoint avec un saut de ligne
          ],
          [
            "Conditions de travail favorables",
            formData.workingConditions ? "Oui" : "Non",
          ],
          [
            "Si non, pourquoi ?",
            !formData.workingConditions ? formData.unfavorableReason : "",
          ],
        ];

        doc.autoTable({
          startY: yOffset, // Position du tableau après le dernier texte
          head: [
            [
              {
                content:
                  "FICHE DE SUIVI TECHNIQUE STAGIAIRE (Questionnaire destiné aux stagiaires)",
                colSpan: 2, // Fusionne les colonnes pour l'en-tête
                styles: {
                  halign: "center", // Centre le texte de l'en-tête
                  fillColor: [192, 192, 192], // Gris clair
                  textColor: [0, 0, 0], // Texte noir
                  fontStyle: "bold", // Texte en gras
                },
              },
            ],
          ],
          body: tableData, // Données du tableau
          theme: "grid",
          styles: {
            fontSize: 10,
            cellPadding: 3, // Espace intérieur des cellules
            overflow: "linebreak", // Gestion du dépassement pour toutes les cellules
          },
          columnStyles: {
            0: {
              cellWidth: 60, // Largeur pour la colonne Question
              fontStyle: "bold", // Texte en gras pour les questions
            },
            1: {
              cellWidth: 120, // Largeur pour la colonne Réponse
              overflow: "linebreak", // Texte passe à la ligne si nécessaire
            },
          },
        });

        // Fonction utilitaire pour ajouter du texte
        // Outer table

        const addTextBottom = (label, value, x, y) => {
          doc.text(`${label}${value || "Non spécifié"}`, x, y);
        };

        // Ajout du texte après le tableau
        // Function to format the date as DD-MM-YYYY
        function formatDate(date) {
          const d = new Date(date);
          const day = ("0" + d.getDate()).slice(-2); // Get the day and ensure it's two digits
          const month = ("0" + (d.getMonth() + 1)).slice(-2); // Get the month and ensure it's two digits
          const year = d.getFullYear(); // Get the year

          return `${day}-${month}-${year}`; // Return the formatted date
        }

        // Call addTextBottom with the formatted date
        addTextBottom(
          "Date de l'entretien : ",
          formatDate(formData.date), // Format the date before passing it
          15,
          doc.lastAutoTable.finalY + 10
        );

        addTextBottom(
          "Administrateur : ",
          formData.administrator,
          15,
          doc.lastAutoTable.finalY + 15
        );
        addTextBottom(
          "Fonction : ",
          formData.function,
          15,
          doc.lastAutoTable.finalY + 20
        );

        // Generate PDF and download
        doc.save("Fiche_Suivi_Stagiaire.pdf");
      })
      .catch((error) => {
        console.error("Erreur de chargement des images :", error);
        alert(
          "Échec du chargement des images. Vérifiez les chemins et réessayez."
        );
      });
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ display: "flex", flexDirection: "column", gap: "10px" }}
    >
      <h1>FICHE DE SUIVI TECHNIQUE STAGIAIRE</h1>

      {/* Personal Information Section */}
      <label>Prénom(s) et Nom du stagiaire :</label>
      <input
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
      />

      <label>Secteur d'activité :</label>
      <input name="sector" value={formData.sector} onChange={handleChange} />

      <label>Raison sociale de l'Entreprise :</label>
      <input name="company" value={formData.company} onChange={handleChange} />

      <label>Poste occupé :</label>
      <input
        name="position"
        value={formData.position}
        onChange={handleChange}
      />

      <label>Nom du Maître de Stage :</label>
      <input
        name="supervisor"
        value={formData.supervisor}
        onChange={handleChange}
      />

      <label>Fonction du Maître de Stage :</label>
      <input
        name="fonction"
        value={formData.fonction}
        onChange={handleChange}
      />

      <label>Téléphone du Maître de Stage :</label>
      <input name="phone" value={formData.phone} onChange={handleChange} />

      <label>Email du Maître de Stage :</label>
      <input name="email" value={formData.email} onChange={handleChange} />

      {/* Skills and Experience Section */}
      <label>
        1. Avez-vous acquis des compétences autres que celles appliquées lors de
        votre formation ou de votre stage ?
      </label>
      <input
        type="checkbox"
        name="skillsAcquired"
        checked={formData.skillsAcquired}
        onChange={handleChange}
      />

      {formData.skillsAcquired && (
        <div>
          <label>Si oui, lesquels ?</label>
          <input
            name="skillsList"
            value={formData.skillsList}
            onChange={handleChange}
          />
        </div>
      )}

      <label>
        2. Avez-vous eu une première expérience dans un domaine lié à votre
        stage actuel ?
      </label>
      <input
        type="checkbox"
        name="firstExperience"
        checked={formData.firstExperience}
        onChange={handleChange}
      />

      {/* Challenges Section */}
      <label>3. Avez-vous rencontré des difficultés pendant le stage ?</label>
      <input
        type="checkbox"
        name="difficulties"
        checked={formData.difficulties}
        onChange={handleChange}
      />

      {formData.difficulties && (
        <div>
          <label>Si oui, lesquels ?</label>
          <input
            name="difficultiesList"
            value={formData.difficultiesList}
            onChange={handleChange}
          />

          <label>Comment les avez-vous résolus ?</label>
          <input
            name="solutions"
            value={formData.solutions}
            onChange={handleChange}
          />
        </div>
      )}

      {/* Job Description Section */}
      <label>
        4. Avez-vous une description de poste ou une feuille de tâches fournie
        par l'entreprise ?
      </label>
      <div>
        <label>
          <input
            type="radio"
            name="hasJobDescription"
            value="yes"
            checked={formData.hasJobDescription === true}
            onChange={() =>
              handleChange({
                target: { name: "hasJobDescription", value: true },
              })
            }
          />
          Oui
        </label>
        <label>
          <input
            type="radio"
            name="hasJobDescription"
            value="no"
            checked={formData.hasJobDescription === false}
            onChange={() =>
              handleChange({
                target: { name: "hasJobDescription", value: false },
              })
            }
          />
          Non
        </label>
      </div>

      <label>
        5. Les tâches que vous effectuez correspondent-elles à votre description
        de poste ou à votre feuille de tâches ?
      </label>
      <input
        type="checkbox"
        name="tasksMatch"
        checked={formData.tasksMatch}
        onChange={handleChange}
      />

      {!formData.tasksMatch && (
        <div>
          <label>Si non, pourquoi ?</label>
          <input
            name="tasksMismatchReason"
            value={formData.tasksMismatchReason}
            onChange={handleChange}
          />
        </div>
      )}

      {/* Tasks Section */}
      <label>
        6. Énumérez quelques tâches que vous avez effectuées ce mois-ci :
      </label>
      <textarea
        name="tasks"
        value={formData.tasks.join("\n")}
        onChange={(e) =>
          setFormData({ ...formData, tasks: e.target.value.split("\n") })
        }
      />

      {/* Working Conditions Section */}
      <label>
        7. Les conditions de travail sont-elles favorables à la réalisation de
        vos tâches ?
      </label>
      <input
        type="checkbox"
        name="workingConditions"
        checked={formData.workingConditions}
        onChange={handleChange}
      />

      {!formData.workingConditions && (
        <div>
          <label>Si non, pourquoi ?</label>
          <input
            name="unfavorableReason"
            value={formData.unfavorableReason}
            onChange={handleChange}
          />
        </div>
      )}

      {/* Comments and Suggestions Section */}
      <label>Vos remarques et suggestions :</label>
      <textarea
        name="comments"
        value={formData.comments}
        onChange={handleChange}
      />

      {/* Footer Section */}
      <label>Date de l'entretien :</label>
      <input
        name="date"
        type="date"
        value={formData.date}
        onChange={handleChange}
      />

      <label>Administrateur :</label>
      <input
        name="administrator"
        value={formData.administrator}
        onChange={handleChange}
      />

      <label>Fonction :</label>
      <input
        name="function"
        value={formData.function}
        onChange={handleChange}
      />

      <button type="submit">Generate PDF</button>
    </form>
  );
}

export default SuiviStagiares;
