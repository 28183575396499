import React, { useState } from "react";
import { jsPDF } from "jspdf";
// import autoTable from "jspdf-autotable";

const SuiviChefEntreprise = () => {
  const [formData, setFormData] = useState({
    entreprise: "",
    secteurActivite: "",
    responsable: "",
    date: "",
    administrator: "",
    function: "",
    email: "",
    stagiaireDispo: "",
    profilsStagiaires: ["", "", ""],
    effetsPositifs: {
      chiffreAffaires: false,
      productivite: false,
      visibilite: false,
      gestion: false,
      autres: "",
    },
    difficulteMiseEnOeuvre: "",
    remarques: "",
    attentes: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        effetsPositifs: {
          ...prev.effetsPositifs,
          [name]: checked,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleArrayChange = (index, value) => {
    const updatedProfiles = [...formData.profilsStagiaires];
    updatedProfiles[index] = value;

    setFormData((prev) => ({
      ...prev,
      profilsStagiaires: updatedProfiles,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data submitted: ", formData);
    alert("Formulaire envoyé avec succès !");
  };

  const generatePDF = () => {
    const doc = new jsPDF("portrait", "mm", "a4");
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      });
    };

    Promise.all([
      loadImage("/img/logoArd.png"),
      loadImage("/img/italie.png"),
      loadImage("/img/paijeft.png"),
    ])
      .then(([imgArd, italie, paijeft]) => {
        // Add title
        doc.setFontSize(12);
        doc.addImage(imgArd, "PNG", 15, 5, 30, 20); // Logo ARD
        doc.addImage(italie, "PNG", 90, 5, 30, 20); // Logo ARD
        doc.addImage(paijeft, "PNG", 160, 5, 30, 20); // Logo ARD
        // Add header
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        // doc.text("FICHE DE SUIVI TECHNIQUE DE L'ENTREPRISE", 105, 33, {
        //   align: "center",
        // });

        // Prepare table data for one column
        const tableData = [
          `Nom de l'entreprise : ${formData.entreprise || "Non spécifié"}`,
          `Secteur d'activité : ${formData.secteurActivite || "Non spécifié"}`,
          `Responsable : ${formData.responsable || "Non spécifié"}`,
          `E-mail : ${formData.email || "Non spécifié"}`,
          `Profils des stagiaires : ${
            formData.profilsStagiaires.filter((p) => p).join(", ") || "N/A"
          }`,
          `Effets positifs : ${
            Object.entries(formData.effetsPositifs)
              .filter(([key, value]) => value && key !== "autres")
              .map(([key]) => key)
              .join(", ") || "Aucun"
          }`,
          `Autres effets : ${formData.effetsPositifs.autres || "N/A"}`,
          `Difficultés : ${formData.difficulteMiseEnOeuvre || "Aucune"}`,
          `Remarques : ${formData.remarques || "Aucune"}`,
          `Attentes : ${formData.attentes || "Aucune"}`,
        ];
        // let yOffset = 30;
        // Add the table with one column
        // doc.autoTable({
        //   startY: yOffset, // Position du tableau après le dernier texte

        //   head: [
        //     [
        //       {
        //         content:
        //           "FICHE DE SUIVI TECHNIQUE DE L'ENTREPRISE (Questionnaire destiné aux Chefs d'entreprise)",
        //         colSpan: 2, // Fusionne les colonnes pour l'en-tête
        //         styles: {
        //           halign: "center", // Centre le texte de l'en-tête
        //           fillColor: [192, 192, 192], // Gris clair
        //           textColor: [0, 0, 0], // Texte noir
        //           fontStyle: "bold", // Texte en gras
        //         },
        //       },
        //     ],
        //   ],
        //   body: tableData.map((text) => [text]), // Wrap each row in an array for a single column
        //   theme: "grid",
        //   styles: {
        //     fontSize: 10,
        //     halign: "left",
        //   },
        //   styles: {
        //     fontSize: 10,
        //     cellPadding: 3, // Espace intérieur des cellules
        //     overflow: "linebreak", // Gestion du dépassement pour toutes les cellules
        //   },
        //   columnStyles: {
        //     0: {
        //       cellWidth: 60, // Largeur pour la colonne Question
        //       fontStyle: "bold", // Texte en gras pour les questions
        //     },
        //     1: {
        //       cellWidth: 120, // Largeur pour la colonne Réponse
        //       overflow: "linebreak", // Texte passe à la ligne si nécessaire
        //     },
        //   },
        //   startY: 35,
        // });
        doc.autoTable({
          startY: 35, // Position of the table below the header and logos
          head: [
            [
              {
                content:
                  "FICHE DE SUIVI TECHNIQUE DE L'ENTREPRISE (Questionnaire destiné aux Chefs d'entreprise)",
                colSpan: 1, // One column to match the body
                styles: {
                  halign: "center", // Center-align the header text
                  fillColor: [192, 192, 192], // Light gray background
                  textColor: [0, 0, 0], // Black text
                  fontStyle: "bold", // Bold font style
                },
              },
            ],
          ],
          body: tableData.map((text) => [text]), // Wrap each data point in an array for one column
          theme: "grid",
          styles: {
            fontSize: 10, // Font size for table content
            cellPadding: 3, // Padding inside the cells
            overflow: "linebreak", // Handle long text wrapping
          },
          columnStyles: {
            0: {
              cellWidth: "auto", // Automatically adjust the column width
            },
          },
        });

        // Footer with date
        const today = new Date();
        const formattedDate = `${today
          .getDate()
          .toString()
          .padStart(2, "0")}-${(today.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${today.getFullYear()}`;
        doc.setFont("helvetica", "normal");
        doc.text(
          `Date de l'entretien: ${formattedDate}`,
          14,
          doc.lastAutoTable.finalY + 10
        );
        doc.setFont("helvetica", "normal");
        doc.text(
          `Administrateur du questionnaire: ${
            formData.administrator || "Aucune"
          }`,
          14,
          doc.lastAutoTable.finalY + 15
        );
        doc.setFont("helvetica", "normal");
        doc.text(
          `Fonction: ${formData.function || "Aucune"}`,
          14,
          doc.lastAutoTable.finalY + 20
        );

        // Save the document
        doc.save(`Fiche_Suivi_${formData.entreprise || "Entreprise"}.pdf`);
      })
      .catch((error) => {
        console.error("Erreur de chargement des images :", error);
        alert(
          "Échec du chargement des images. Vérifiez les chemins et réessayez."
        );
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1>Fiche de Suivi Technique de l'Entreprise</h1>
      <section>
        <h2>Identification de l'entreprise</h2>
        <label>
          Nom de l'entreprise:
          <input
            type="text"
            name="entreprise"
            value={formData.entreprise}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <label>
          Secteur d'activité:
          <input
            type="text"
            name="secteurActivite"
            value={formData.secteurActivite}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <label>
          Responsable:
          <input
            type="text"
            name="responsable"
            value={formData.responsable}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <label>
          E-mail:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>
      </section>

      <section>
        <h2>Évaluation de l'apport technique</h2>
        <label>
          Le nombre de stagiaires mis à votre disposition répond-il à vos
          besoins ?
          <select
            name="stagiaireDispo"
            value={formData.stagiaireDispo}
            onChange={handleChange}
          >
            <option value="">-- Sélectionner --</option>
            <option value="oui">Oui</option>
            <option value="non">Non</option>
          </select>
        </label>

        {formData.stagiaireDispo === "non" && (
          <div>
            <p>Si non, quels sont les profils qui vous manquent ?</p>
            {formData.profilsStagiaires.map((profil, index) => (
              <input
                key={index}
                type="text"
                value={profil}
                onChange={(e) => handleArrayChange(index, e.target.value)}
                placeholder={`Profil ${index + 1}`}
              />
            ))}
          </div>
        )}

        <p>
          La présence des stagiaires a-t-elle des effets sur le fonctionnement
          de l’entreprise ?
        </p>
        <label>
          <input
            type="checkbox"
            name="chiffreAffaires"
            checked={formData.effetsPositifs.chiffreAffaires}
            onChange={handleChange}
          />
          Le chiffre d'affaires
        </label>
        <label>
          <input
            type="checkbox"
            name="productivite"
            checked={formData.effetsPositifs.productivite}
            onChange={handleChange}
          />
          La productivité
        </label>
        <label>
          <input
            type="checkbox"
            name="visibilite"
            checked={formData.effetsPositifs.visibilite}
            onChange={handleChange}
          />
          La visibilité
        </label>
        <label>
          <input
            type="checkbox"
            name="gestion"
            checked={formData.effetsPositifs.gestion}
            onChange={handleChange}
          />
          La gestion
        </label>
        <label>
          Autres:
          <input
            type="text"
            name="autres"
            value={formData.effetsPositifs.autres}
            onChange={(e) =>
              handleChange({
                ...e,
                name: "autres",
                value: e.target.value,
              })
            }
          />
        </label>
      </section>

      <section>
        <h2>Autres Informations</h2>
        <label>
          Rencontrez-vous des difficultés dans la mise en œuvre du programme
          PAIJEF ?
          <textarea
            name="difficulteMiseEnOeuvre"
            value={formData.difficulteMiseEnOeuvre}
            onChange={handleChange}
          ></textarea>
        </label>
        <label>
          Vos remarques et suggestions pour le PAIJEF en terme d'asistance
          technique:
          <textarea
            name="remarques"
            value={formData.remarques}
            onChange={handleChange}
          ></textarea>
        </label>
        <label>
          Vos attentes du programme PAIJEF ?
          <textarea
            name="attentes"
            value={formData.attentes}
            onChange={handleChange}
          ></textarea>
        </label>
        <br />

        {/* Footer Section */}
        <label>Date de l'entretien :</label>
        <input
          name="date"
          type="date"
          value={formData.date}
          onChange={handleChange}
        />

        <label>Administrateur :</label>
        <input
          name="administrator"
          value={formData.administrator}
          onChange={handleChange}
        />

        <label>Fonction :</label>
        <input
          name="function"
          value={formData.function}
          onChange={handleChange}
        />
      </section>

      <button type="button" onClick={generatePDF}>
        Générer le PDF
      </button>
    </form>
  );
};

export default SuiviChefEntreprise;
