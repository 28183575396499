import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import BudgetSelect from "./BudgetSelect"; // Import the BudgetSelect component
import "./MissionForm.css";
export default function MissionForm() {
  const [codeType, setCodeType] = useState("Manual"); // Default to "Generate"
  const [code, setCode] = useState(""); // Store the generated or manual code
  // Define generateCode with useCallback to avoid recreation on every render
  const generateCode = useCallback(() => {
    if (codeType === "Generate") {
      const lastCode = parseInt(localStorage.getItem("lastCode") || "0", 10);
      const newCode = String(lastCode + 1).padStart(4, "0");
      localStorage.setItem("lastCode", newCode);
      setCode(newCode);
    }
  }, [codeType]); // Only re-create if codeType changes
  // useEffect(() => {
  //   if (codeType === "Generate") {
  //     generateCode();
  //   }
  // }, [codeType]);
  useEffect(() => {
    generateCode();
  }, [generateCode]);

  // Handle dropdown change (Generate or Manual)
  const handleCodeChange = (e) => {
    setCodeType(e.target.value);
  };

  // Function to generate 4-digit code
  // const generateCode = () => {
  //   if (codeType === "Generate") {
  //     const lastCode = parseInt(localStorage.getItem("lastCode") || "0", 10);
  //     const newCode = String(lastCode + 1).padStart(4, "0"); // Increment and pad to 4 digits
  //     localStorage.setItem("lastCode", newCode); // Save the generated code
  //     setCode(newCode); // Set the code for the form
  //   }
  // };

  // Function to handle manual code input
  const handleManualCodeChange = (e) => {
    setCode(e.target.value);
  };

  const [formData, setFormData] = useState({
    name: "",
    role: "",
    location: [],
    reason: "",
    transport: "",
    departureDate: "",
    returnDate: "",
    customBudget: "", // To store custom budget if applicable
    budget: "",
    dateCreateDoc: "",
  });

  const roleMapping = {
    "Dr. Oumar FAYE": "Directeur",
    "M. Abdourakhmane DIA": "Chef de la division Suivi-Évaluation",
    "Mme Fatou MBENGUE": "Secretaire des divisions",
    "M. Omar NDOYE": "Chef de la division Appui à la Maîtrise d'Ouvrage",
    "M. El Hadji Babacar GUEYE":
      "Chef de la division Planification et Formation",
    "Mme Sokhna MBAYE": "Agent Comptable",
    "Mme Absa NDIAYE":
      "Assistante administrative et Appui à la Maîtrise d'Ouvrage",
    "Mme Ndèye Diarra THIAM": "Assistante Appui à la Maîtrise d'Ouvrage",
    "M. Aliou Ndiaye Fara DIAW": "Assistant comptable",
    "M. Ali KANE": "Chauffeur",
    "M. Omar TALL": "Chauffeur",
    "M. Abdoulaye SENE": "Chauffeur",
  };

  const handleChange = (e) => {
    const { id, value, name, selectedOptions } = e.target;

    // For fields with `id === "name"`
    if (id === "name") {
      const updatedRole = roleMapping[value] || "";
      setFormData((prevData) => ({
        ...prevData,
        name: value,
        role: updatedRole,
      }));
    }
    // For fields with `name === "location"`
    else if (name === "location") {
      const selectedLocations = Array.from(
        selectedOptions,
        (option) => option.value
      );
      setFormData((prevData) => ({
        ...prevData,
        [name]: selectedLocations, // Store multiple selected values as an array
      }));
    }
    // For all other fields
    else {
      setFormData((prevData) => ({
        ...prevData,
        [id || name]: value, // Use `id` or `name` as the key for the value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:8000/api/missions",
        formData
      );
      if (response.status === 200 || response.status === 201) {
        alert("Mission saved successfully!");
      } else {
        alert("Unexpected response received.");
      }
    } catch (error) {
      console.error("Error saving mission:", error.message);
      alert("Error saving mission.");
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF("portrait", "mm", "a4");

    // Vérification et gestion du budget
    const budgetToUse =
      formData.budget === "AUTRES" ? formData.customBudget : formData.budget;

    // Fonction pour charger une image
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      });
    };

    Promise.all([loadImage("/img/logoArd.png")])
      .then(([imgArd]) => {
        // **Entête**
        doc.addImage(imgArd, "PNG", 45, 5, 30, 20); // Logo ARD
        doc.setFont("helvetica", "bold");
        doc.setFontSize(12);
        doc.text("RÉPUBLIQUE DU SÉNÉGAL", 35, 30); // Centré horizontalement avec marge
        doc.text("******", 55, 35);
        doc.text("RÉGION DE THIÈS", 40, 40);
        doc.text("******", 55, 45);
        doc.text("AGENCE RÉGIONALE DE DÉVELOPPEMENT", 20, 50);

        //Ligne bleue (séparateur sous l'en-tête)
        doc.setDrawColor(0, 0, 139); // Bleu foncé
        doc.setLineWidth(0.5);
        doc.line(20, 52.5, 190, 52.5);

        // Date et numéro de document
        const date1 = new Date(formData.dateCreateDoc);
        if (!isNaN(date1.getTime())) {
          const formattedDate1 = new Intl.DateTimeFormat("fr-FR", {
            day: "numeric",
            month: "long",
            year: "numeric",
          }).format(date1);
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          doc.text(`N° ${code || "..........."} / ARDTH`, 190, 15, {
            align: "right",
          });
          doc.text(`Thiès, le ${formattedDate1}`, 190, 50, { align: "right" });
        }

        const rectX = 55;
        const rectY = 55;
        const rectWidth = 100;
        const rectHeight = 15;

        // Shadow rectangle (slightly offset and lighter color)
        const shadowOffset = 4; // Shadow size
        doc.setDrawColor(150, 150, 150); // Gray for shadow
        doc.setFillColor(200, 200, 200); // Fill color for shadow (lighter gray)
        doc.rect(
          rectX + shadowOffset,
          rectY + shadowOffset,
          rectWidth,
          rectHeight,
          "F"
        );

        // Main rectangle
        doc.setDrawColor(15, 15, 15); // Black for border
        doc.setFillColor(255, 255, 255); // White for fill
        doc.rect(rectX, rectY, rectWidth, rectHeight, "FD"); // 'FD' for fill and border

        // Text inside the rectangle
        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        const textX = rectX + rectWidth / 2; // Centered horizontally
        const textY = rectY + rectHeight / 2 + 2.5; // Centered vertically with adjustment
        doc.text("ORDRE DE MISSION", textX, textY, { align: "center" });

        // **Contenu principal**
        doc.setFont("helvetica", "normal");
        doc.setFontSize(14);

        // Fonction pour formater une date
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          return isNaN(date.getTime())
            ? ""
            : new Intl.DateTimeFormat("fr-FR").format(date);
        };

        const formattedDepartureDate = formatDate(formData.departureDate);
        const formattedReturnDate = formatDate(formData.returnDate);

        const content = [
          [`Nom :`, `${formData.name}`],
          [`Poste :`, `${formData.role} ARD THIES`],
          [`Destination :`, `${formData.location}`],
          [`Motif :`, doc.splitTextToSize(formData.reason, 120)],
          [`Moyen de Transport :`, `${formData.transport}`],
          [`Date de Départ :`, formattedDepartureDate],
          [`Date de Retour :`, formattedReturnDate],
          [`Imputation Budgétaire :`, `${budgetToUse}`],
        ];

        let startY = 80; // Départ après le titre
        const startXLabel = 20; // Position gauche pour les libellés
        const startXValue = 80; // Position gauche pour les valeurs

        content.forEach(([label, value]) => {
          doc.setFont("helvetica", "bold");
          doc.text(label, startXLabel, startY);
          doc.setFont("helvetica", "normal");
          doc.text(value, startXValue, startY);
          startY += 10; // Espacement entre les lignes
        });

        // **Pied de page**
        doc.setFont("helvetica", "bold");
        doc.text("Le Directeur", 165, 170, { align: "right" });
        doc.text("Dr Oumar FAYE", 170, 210, { align: "right" });

        doc.setLineWidth(0.5);
        doc.line(15, 260, 195, 260); // Ligne horizontale

        doc.setFont("helvetica", "italic");
        doc.setFontSize(10);
        doc.text(
          "Avenue Félix Houphouët BOIGNY | Tel: 33-952-13-24 | Fax: 33-952-13-23 | BP: 702",
          105,
          265,
          { align: "center" }
        );

        // Sauvegarde du fichier
        const today = new Date();
        const formattedDate = `${today
          .getDate()
          .toString()
          .padStart(2, "0")}-${(today.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${today.getFullYear()}`;
        doc.save(`Ordre_de_Mission_${formData.name}_${formattedDate}.pdf`);
      })
      .catch((error) => {
        console.error("Erreur de chargement des images :", error);
        alert(
          "Échec du chargement des images. Vérifiez les chemins et réessayez."
        );
      });
  };

  // function formatDate(dateString) {
  //   const date = new Date(dateString);
  //   if (isNaN(date.getTime())) {
  //     return "";
  //   }
  //   return new Intl.DateTimeFormat("fr-FR", {
  //     day: "numeric",
  //     month: "long",
  //     year: "numeric",
  //   }).format(date);
  // }

  return (
    <div className="form-container d-flex justify-content-center align-items-center">
      <form onSubmit={handleSubmit} className="mission-form mt-4">
        <h1 className="mb-4 text-center">Ordre de Mission</h1>

        <div className="mb-3 form-group">
          <label htmlFor="name" className="form-label">
            Nom:
          </label>
          <select
            id="name"
            className="form-select"
            value={formData.name}
            onChange={handleChange}
          >
            <option value="">Select Name</option>
            <option value="Dr. Oumar FAYE">Dr. Oumar FAYE</option>
            <option value="M. Abdourakhmane DIA">M. Abdourakhmane DIA</option>
            <option value="Mme Fatou MBENGUE">Mme Fatou MBENGUE</option>
            <option value="M. Omar NDOYE">M. Omar NDOYE</option>
            <option value="M. El Hadji Babacar GUEYE">
              M. El Hadji Babacar GUEYE
            </option>
            <option value="Mme Sokhna MBAYE">Mme Sokhna MBAYE</option>
            <option value="Mme Absa NDIAYE">Mme Absa NDIAYE</option>
            <option value="Mme Ndèye Diarra THIAM">
              Mme Ndèye Diarra THIAM
            </option>
            <option value="M. Aliou Ndiaye Fara DIAW">
              M. Aliou Ndiaye Fara DIAW
            </option>
            <option value="M. Ali KANE">M. Ali KANE</option>
            <option value="M. Omar TALL">M. Omar TALL</option>
            <option value="M. Abdoulaye SENE">M. Abdoulaye SENE</option>
          </select>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="role" className="form-label">
            Rôle:
          </label>
          <input
            type="text"
            id="role"
            className="form-control"
            value={formData.role}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="location" className="form-label">
            Lieu:
          </label>
          <select
            id="location"
            className="form-select"
            multiple
            name="location"
            value={formData.location}
            onChange={handleChange}
          >
            <option value="">Select Location</option>
            <option
              class="department"
              value="Conseil Département de Thiès"
              disabled
            >
              {/* Département de Thiès(17) */}
              Département de Thiès
            </option>
            <option value="Tassette">Conseil Département de Thiès</option>
            <option value="Tassette">Tassette</option>
            <option value="Notto">Notto</option>
            <option value="Thiénaba">Thiénaba</option>
            <option value="Ngoundiane">Ngoundiane</option>
            <option value="Touba Toul">Touba Toul</option>
            <option value="Pout">Pout</option>
            <option value="Keur Moussa">Keur Moussa</option>
            <option value="Fandène">Fandène</option>
            <option value="Diender">Diender</option>
            <option value="Ndiéyène Sirakh">Ndiéyène Sirakh</option>
            <option value="Thiès Est">Thiès Est</option>
            <option value="Thiès Ouest">Thiès Ouest</option>
            <option value="Thiès Nord">Thiès Nord</option>
            {/* <option value="Thiès Sud">Thiès Sud</option> */}
            <option value="Ville de Thiès">Ville de Thiès</option>
            <option value="Khombole">Khombole</option>

            <option
              class="department"
              value="Département de Tivaouane"
              disabled
            >
              {/* Département de Tivaouane(19) */}
              Département de Tivaouane
            </option>
            <option value="Tassette">Conseil Département de Tivaouane</option>
            <option value="Tivaouane">Tivaouane</option>
            <option value="Darou Khoudoss">Darou Khoudoss</option>
            <option value="Mboro">Mboro</option>
            <option value="Méouane">Méouane</option>
            <option value="Mékhé">Mékhé</option>
            <option value="Koul">Koul</option>
            <option value="Mérina Dakhar">Mérina Dakhar</option>
            <option value="Niakhène">Niakhène</option>
            <option value="Pékèsse">Pékèsse</option>
            <option value="Thilmakha">Thilmakha</option>
            <option value="Ngandiouf">Ngandiouf</option>
            <option value="Mbayène">Mbayène</option>
            <option value="Mont Rolland">Mont Rolland</option>
            <option value="Notto Gouye Diama">Notto Gouye Diama</option>
            <option value="Pambal">Pambal</option>
            <option value="Chérif Lo">Chérif Lo</option>
            <option value="Pire Goureye">Pire Goureye</option>
            <option value="Taïba Ndiaye">Taïba Ndiaye</option>

            <option
              class="department"
              value="Conseil Département de Mbour"
              disabled
            >
              {/* Département de Mbour(16) */}
              Département de Mbour
            </option>
            <option value="Tassette">Conseil Département de Mbour</option>
            <option value="Mbour">Mbour</option>
            <option value="Saly Portudal">Saly Portudal</option>
            <option value="Ngaparou">Ngaparou</option>
            <option value="Somone">Somone</option>
            <option value="Malicounda">Malicounda</option>
            <option value="Sindia">Sindia</option>
            <option value="Diass">Diass</option>
            <option value="Kayar">Kayar</option>
            <option value="Nguékhokh">Nguékhokh</option>
            <option value="Sandiara">Sandiara</option>
            <option value="Sessene">Sessene </option>
            <option value="Ndiaganiao">Ndiaganiao</option>
            <option value="Thiadiaye">Thiadiaye </option>
            <option value="Fissel">Fissel</option>
            <option value="Ngueniene">Ngueniene</option>
            <option value="Joal">Joal-Fadiouth</option>
            <option value="Popenguine">Popenguine</option>
          </select>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="reason" className="form-label">
            Motif:
          </label>
          <input
            type="text"
            id="reason"
            className="form-control"
            value={formData.reason}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="transport" className="form-label">
            Moyen de Transport:
          </label>
          <select
            id="transport"
            className="form-select"
            value={formData.transport}
            onChange={handleChange}
          >
            <option value="">Select Transport</option>
            <option value="AA 56 0DQ">AA 56 0DQ</option>
            <option value="TH0001 EP 104">TH0001 EP 104</option>
            <option value="0337 EP 104">0337 EP 104</option>
            <option value="AD 1930 TTA1">AD 1930 TTA1</option>
          </select>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="departureDate" className="form-label">
            Date de Départ:
          </label>
          <input
            type="date"
            id="departureDate"
            className="form-control"
            value={formData.departureDate}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="returnDate" className="form-label">
            Date de Retour:
          </label>
          <input
            type="date"
            id="returnDate"
            className="form-control"
            value={formData.returnDate}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="dateCreateDoc" className="form-label">
            Date de création du document:
          </label>
          <input
            type="date"
            id="dateCreateDoc"
            className="form-control"
            value={formData.dateCreateDoc}
            onChange={handleChange}
            required
          />
        </div>

        <BudgetSelect formData={formData} setFormData={setFormData} />

        <div className="form-group mb-3">
          <label htmlFor="missionCode" className="form-label">
            Code Mission:
          </label>
          {codeType === "Generate" ? (
            <input
              type="text"
              id="missionCode"
              className="form-control"
              value={`N°${code}/ ARDTH`}
              readOnly
            />
          ) : (
            <input
              type="text"
              id="missionCode"
              className="form-control"
              value={code}
              onChange={handleManualCodeChange}
            />
          )}
        </div>

        <div className="form-group mb-3">
          <label htmlFor="codeType" className="form-label">
            Régulariser le de Code:
          </label>
          <select
            id="codeType"
            className="form-select"
            value={codeType}
            onChange={handleCodeChange}
          >
            <option value="Generate">Générer</option>
            <option value="Manual">Manuel</option>
          </select>
        </div>

        <div className="d-flex justify-content-between">
          <button
            type="button"
            onClick={generatePDF}
            className="btn btn-secondary"
          >
            Générer PDF
          </button>
        </div>
      </form>
    </div>
  );
  //<button type="submit" className="btn btn-primary">Enregistrer Mission</button>
}
