import React, { useState } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import "./SuiviStagiares.css";

function FicheMaitreStage() {
  const [formData, setFormData] = useState({
    companyName: "",
    activitySector: "",
    supervisorName: "",
    supervisorPosition: "",
    companyContact: "",
    supervisorEmail: "",
    traineeName: "",
    traineeDiploma: "",
    traineeSector: "",
    traineePhone: "",
    traineeEmail: "",
    tasks: "",
    hasAptitudes: null,
    aptitudeImprovement: "",
    providedSupport: null,
    providedEquipment: null,
    qualities: {
      punctuality: "",
      motivation: "",
      initiative: "",
      responseToCriticism: "",
      taskExecution: "",
    },
    hasProgressed: null,
    progressDetails: "",
    evaluationPlanned: null,
    evaluationFrequency: "",
    coachingDifficulties: null,
    difficultiesDetails: "",
    resolutionDetails: "",
    generalFeedback: "",
    interviewDate: "",
    adminName: "",
    adminPosition: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleQualitiesChange = (quality, value) => {
    setFormData((prev) => ({
      ...prev,
      qualities: {
        ...prev.qualities,
        [quality]: value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      });
    };

    const doc = new jsPDF();

    Promise.all([
      loadImage("/img/logoArd.png"),
      loadImage("/img/italie.png"),
      loadImage("/img/paijeft.png"),
    ])
      .then(([imgArd, italie, paijeft]) => {
        // Add title
        // Add logos
        doc.setFontSize(12);
        doc.addImage(imgArd, "PNG", 15, 5, 30, 20); // Logo ARD
        doc.addImage(italie, "PNG", 90, 5, 30, 20); // Logo Italie
        doc.addImage(paijeft, "PNG", 160, 5, 30, 20); // Logo PAIJEF

        // Add margin before the title
        let yOffset = 35; // Increase this value to add space between the logos and the title

        // Title
        doc.setFontSize(16);
        doc.text(
          "FICHE DE SUIVI TECHNIQUE DU STAGIAIRE",
          105,
          yOffset,
          null,
          null,
          "center"
        );
        yOffset = 40;
        doc.setFontSize(14);
        doc.text(
          "(Questionnaire destiné au Maître de Stage)",
          105,
          yOffset,
          null,
          null,
          "center"
        );

        yOffset += 10; // Increase the yOffset to avoid overlap with the title

        const addSection = (title, content) => {
          doc.setFontSize(14);
          doc.text(title, 10, yOffset);
          yOffset += 6;
          doc.setFontSize(12);
          content.forEach((line) => {
            doc.text(line, 10, yOffset);
            yOffset += 6;
          });
          yOffset += 4;
        };

        const qualitiesTable = [
          [
            "Qualité",
            "Très satisfaisant",
            "Satisfaisant",
            "Passable",
            "Insuffisant",
          ],
          [
            "Ponctualité-Assiduité",
            formData.qualities.punctuality === "Très satisfaisant" ? "X" : "",
            formData.qualities.punctuality === "Satisfaisant" ? "X" : "",
            formData.qualities.punctuality === "Passable" ? "X" : "",
            formData.qualities.punctuality === "Insuffisant" ? "X" : "",
          ],
          [
            "Motivation pour le travail",
            formData.qualities.motivation === "Très satisfaisant" ? "X" : "",
            formData.qualities.motivation === "Satisfaisant" ? "X" : "",
            formData.qualities.motivation === "Passable" ? "X" : "",
            formData.qualities.motivation === "Insuffisant" ? "X" : "",
          ],
          [
            "Proposition d’initiative",
            formData.qualities.initiative === "Très satisfaisant" ? "X" : "",
            formData.qualities.initiative === "Satisfaisant" ? "X" : "",
            formData.qualities.initiative === "Passable" ? "X" : "",
            formData.qualities.initiative === "Insuffisant" ? "X" : "",
          ],
          [
            "Réaction positive aux critiques",
            formData.qualities.responseToCriticism === "Très satisfaisant"
              ? "X"
              : "",
            formData.qualities.responseToCriticism === "Satisfaisant"
              ? "X"
              : "",
            formData.qualities.responseToCriticism === "Passable" ? "X" : "",
            formData.qualities.responseToCriticism === "Insuffisant" ? "X" : "",
          ],
          [
            "Exécution correcte des tâches",
            formData.qualities.taskExecution === "Très satisfaisant" ? "X" : "",
            formData.qualities.taskExecution === "Satisfaisant" ? "X" : "",
            formData.qualities.taskExecution === "Passable" ? "X" : "",
            formData.qualities.taskExecution === "Insuffisant" ? "X" : "",
          ],
        ];

        // Sections
        addSection("I. Identification de l’entreprise", [
          `Raison sociale de l’entreprise : ${
            formData.companyName || "Non spécifié"
          }`,
          `Secteur d’activité : ${formData.activitySector || "Non spécifié"}`,
          `Tuteur de stage : ${formData.supervisorName || "Non spécifié"} (${
            formData.supervisorPosition || "Non spécifié"
          })`,
          `Contact : ${formData.companyContact || "Non spécifié"} | E-mail : ${
            formData.supervisorEmail || "Non spécifié"
          }`,
        ]);

        addSection("II. Identification du stagiaire", [
          `Prénom(s) et Nom : ${formData.traineeName || "Non spécifié"}`,
          `Diplôme et niveau d’étude : ${
            formData.traineeDiploma || "Non spécifié"
          }`,
          `Secteur de stage : ${formData.traineeSector || "Non spécifié"}`,
          `Contact : ${formData.traineePhone || "Non spécifié"} | E-mail : ${
            formData.traineeEmail || "Non spécifié"
          }`,
        ]);

        addSection("III. Évaluation du stagiaire", [
          `1. Tâches confiées : ${formData.tasks || "Non spécifié"}`,
          `2. Aptitudes à accomplir les tâches : ${
            formData.hasAptitudes ? "Oui" : "Non"
          }`,
          formData.hasAptitudes === false
            ? `Mesures pour mise à niveau : ${
                formData.aptitudeImprovement || "Non spécifié"
              }`
            : "",
          `3. Moyens d’accompagnement : ${
            formData.providedSupport ? "Oui" : "Non"
          }`,
          `   Matériels/Équipements fournis : ${
            formData.providedEquipment ? "Oui" : "Non"
          }`,
        ]);
        doc.autoTable({
          startY: yOffset,
          head: [qualitiesTable[0]],
          body: qualitiesTable.slice(1),
        });

        yOffset = doc.lastAutoTable.finalY + 10;

        addSection("", [
          `5. Progression des aptitudes : ${
            formData.hasProgressed ? "Oui" : "Non"
          }`,
          formData.hasProgressed
            ? `Domaines : ${formData.progressDetails || "Non spécifié"}`
            : "",
          `6. Évaluation prévue : ${
            formData.evaluationPlanned ? "Oui" : "Non"
          }`,
          formData.evaluationPlanned
            ? `Périodicité : ${formData.evaluationFrequency || "Non spécifié"}`
            : "",
          `7. Difficultés rencontrées : ${
            formData.coachingDifficulties ? "Oui" : "Non"
          }`,
          formData.coachingDifficulties
            ? `Détails : ${formData.difficultiesDetails || "Non spécifié"}`
            : "",
          formData.coachingDifficulties
            ? `Résolution : ${formData.resolutionDetails || "Non spécifié"}`
            : "",
          `Appréciation globale : ${
            formData.generalFeedback || "Non spécifié"
          }`,
        ]);

        addSection("", [
          `Date de l’entretien : ${formData.interviewDate || "Non spécifié"}`,
          `Administrateur : ${formData.adminName || "Non spécifié"} (${
            formData.adminPosition || "Non spécifié"
          })`,
        ]);
        // Save the PDF
        doc.save("Fiche_Suivi_Stagiaire.pdf");
      })
      .catch((error) => {
        console.error("Erreur de chargement des images :", error);
        alert(
          "Échec du chargement des images. Vérifiez les chemins et réessayez."
        );
      });
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ display: "flex", flexDirection: "column", gap: "10px" }}
    >
      <h1>FICHE DE SUIVI TECHNIQUE DU STAGIAIRE</h1>
      <h6> (Questionnaire destiné au Maître de Stage)</h6>
      <label>
        Nom de l’entreprise:
        <input
          type="text"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
        />
      </label>
      <label>
        Secteur d’activité:
        <input
          type="text"
          name="activitySector"
          value={formData.activitySector}
          onChange={handleChange}
        />
      </label>
      <label>
        Tuteur de stage:
        <input
          type="text"
          name="supervisorName"
          value={formData.supervisorName}
          onChange={handleChange}
        />
      </label>
      <label>
        Position du tuteur:
        <input
          type="text"
          name="supervisorPosition"
          value={formData.supervisorPosition}
          onChange={handleChange}
        />
      </label>
      <label>
        Contact de l’entreprise:
        <input
          type="text"
          name="companyContact"
          value={formData.companyContact}
          onChange={handleChange}
        />
      </label>
      <label>
        Email du tuteur:
        <input
          type="email"
          name="supervisorEmail"
          value={formData.supervisorEmail}
          onChange={handleChange}
        />
      </label>
      <label>
        Prénom et nom du stagiaire:
        <input
          type="text"
          name="traineeName"
          value={formData.traineeName}
          onChange={handleChange}
        />
      </label>
      <label>
        Diplôme du stagiaire:
        <input
          type="text"
          name="traineeDiploma"
          value={formData.traineeDiploma}
          onChange={handleChange}
        />
      </label>
      <label>
        Secteur de stage:
        <input
          type="text"
          name="traineeSector"
          value={formData.traineeSector}
          onChange={handleChange}
        />
      </label>
      <label>
        Contact du stagiaire:
        <input
          type="text"
          name="traineePhone"
          value={formData.traineePhone}
          onChange={handleChange}
        />
      </label>
      <label>
        Email du stagiaire:
        <input
          type="email"
          name="traineeEmail"
          value={formData.traineeEmail}
          onChange={handleChange}
        />
      </label>
      {/* Qualities selection */}
      <label>
        Ponctualité:
        <select
          name="punctuality"
          value={formData.qualities.punctuality}
          onChange={(e) => handleQualitiesChange("punctuality", e.target.value)}
        >
          <option value="">Sélectionnez</option>
          <option value="Très satisfaisant">Très satisfaisant</option>
          <option value="Satisfaisant">Satisfaisant</option>
          <option value="Passable">Passable</option>
          <option value="Insuffisant">Insuffisant</option>
        </select>
      </label>
      <label>
        Motivation:
        <select
          name="motivation"
          value={formData.qualities.motivation}
          onChange={(e) => handleQualitiesChange("motivation", e.target.value)}
        >
          <option value="">Sélectionnez</option>
          <option value="Très satisfaisant">Très satisfaisant</option>
          <option value="Satisfaisant">Satisfaisant</option>
          <option value="Passable">Passable</option>
          <option value="Insuffisant">Insuffisant</option>
        </select>
      </label>
      <label>
        Initiative:
        <select
          name="initiative"
          value={formData.qualities.initiative}
          onChange={(e) => handleQualitiesChange("initiative", e.target.value)}
        >
          <option value="">Sélectionnez</option>
          <option value="Très satisfaisant">Très satisfaisant</option>
          <option value="Satisfaisant">Satisfaisant</option>
          <option value="Passable">Passable</option>
          <option value="Insuffisant">Insuffisant</option>
        </select>
      </label>
      <label>
        Réponse aux critiques:
        <select
          name="responseToCriticism"
          value={formData.qualities.responseToCriticism}
          onChange={(e) =>
            handleQualitiesChange("responseToCriticism", e.target.value)
          }
        >
          <option value="">Sélectionnez</option>
          <option value="Très satisfaisant">Très satisfaisant</option>
          <option value="Satisfaisant">Satisfaisant</option>
          <option value="Passable">Passable</option>
          <option value="Insuffisant">Insuffisant</option>
        </select>
      </label>
      <label>
        Exécution des tâches:
        <select
          name="taskExecution"
          value={formData.qualities.taskExecution}
          onChange={(e) =>
            handleQualitiesChange("taskExecution", e.target.value)
          }
        >
          <option value="">Sélectionnez</option>
          <option value="Très satisfaisant">Très satisfaisant</option>
          <option value="Satisfaisant">Satisfaisant</option>
          <option value="Passable">Passable</option>
          <option value="Insuffisant">Insuffisant</option>
        </select>
      </label>
      {/* Additional sections */}
      <label>
        Tâches confiées:
        <textarea name="tasks" value={formData.tasks} onChange={handleChange} />
      </label>
      <label>
        Aptitudes à accomplir les tâches:
        <input
          type="checkbox"
          name="hasAptitudes"
          checked={formData.hasAptitudes === true}
          onChange={(e) =>
            handleChange({
              target: {
                name: "hasAptitudes",
                value: e.target.checked ? true : false,
              },
            })
          }
        />{" "}
        Oui
        <input
          type="checkbox"
          name="hasAptitudes"
          checked={formData.hasAptitudes === false}
          onChange={(e) =>
            handleChange({
              target: {
                name: "hasAptitudes",
                value: e.target.checked ? false : true,
              },
            })
          }
        />{" "}
        Non
      </label>
      {formData.hasAptitudes === false && (
        <label>
          Mesures pour mise à niveau:
          <textarea
            name="aptitudeImprovement"
            value={formData.aptitudeImprovement}
            onChange={handleChange}
          />
        </label>
      )}
      <label>
        Moyens d’accompagnement:
        <input
          type="checkbox"
          name="providedSupport"
          checked={formData.providedSupport === true}
          onChange={(e) =>
            handleChange({
              target: {
                name: "providedSupport",
                value: e.target.checked ? true : false,
              },
            })
          }
        />{" "}
        Oui
        <input
          type="checkbox"
          name="providedSupport"
          checked={formData.providedSupport === false}
          onChange={(e) =>
            handleChange({
              target: {
                name: "providedSupport",
                value: e.target.checked ? false : true,
              },
            })
          }
        />{" "}
        Non
      </label>
      <label>
        Matériels/Équipements fournis:
        <input
          type="checkbox"
          name="providedEquipment"
          checked={formData.providedEquipment === true}
          onChange={(e) =>
            handleChange({
              target: {
                name: "providedEquipment",
                value: e.target.checked ? true : false,
              },
            })
          }
        />{" "}
        Oui
        <input
          type="checkbox"
          name="providedEquipment"
          checked={formData.providedEquipment === false}
          onChange={(e) =>
            handleChange({
              target: {
                name: "providedEquipment",
                value: e.target.checked ? false : true,
              },
            })
          }
        />{" "}
        Non
      </label>
      <label>
        Progression des aptitudes:
        <input
          type="checkbox"
          name="hasProgressed"
          checked={formData.hasProgressed === true}
          onChange={(e) =>
            handleChange({
              target: {
                name: "hasProgressed",
                value: e.target.checked ? true : false,
              },
            })
          }
        />{" "}
        Oui
        <input
          type="checkbox"
          name="hasProgressed"
          checked={formData.hasProgressed === false}
          onChange={(e) =>
            handleChange({
              target: {
                name: "hasProgressed",
                value: e.target.checked ? false : true,
              },
            })
          }
        />{" "}
        Non
      </label>
      {formData.hasProgressed && (
        <label>
          Domaines de progression:
          <textarea
            name="progressDetails"
            value={formData.progressDetails}
            onChange={handleChange}
          />
        </label>
      )}
      <label>
        Évaluation prévue:
        <input
          type="checkbox"
          name="evaluationPlanned"
          checked={formData.evaluationPlanned === true}
          onChange={(e) =>
            handleChange({
              target: {
                name: "evaluationPlanned",
                value: e.target.checked ? true : false,
              },
            })
          }
        />{" "}
        Oui
        <input
          type="checkbox"
          name="evaluationPlanned"
          checked={formData.evaluationPlanned === false}
          onChange={(e) =>
            handleChange({
              target: {
                name: "evaluationPlanned",
                value: e.target.checked ? false : true,
              },
            })
          }
        />{" "}
        Non
      </label>
      {formData.evaluationPlanned && (
        <label>
          Périodicité de l'évaluation:
          <input
            type="text"
            name="evaluationFrequency"
            value={formData.evaluationFrequency}
            onChange={handleChange}
          />
        </label>
      )}
      <label>
        Difficultés rencontrées lors de l’accompagnement:
        <input
          type="checkbox"
          name="coachingDifficulties"
          checked={formData.coachingDifficulties === true}
          onChange={(e) =>
            handleChange({
              target: {
                name: "coachingDifficulties",
                value: e.target.checked ? true : false,
              },
            })
          }
        />{" "}
        Oui
        <input
          type="checkbox"
          name="coachingDifficulties"
          checked={formData.coachingDifficulties === false}
          onChange={(e) =>
            handleChange({
              target: {
                name: "coachingDifficulties",
                value: e.target.checked ? false : true,
              },
            })
          }
        />{" "}
        Non
      </label>
      {formData.coachingDifficulties && (
        <label>
          Détails des difficultés:
          <textarea
            name="difficultiesDetails"
            value={formData.difficultiesDetails}
            onChange={handleChange}
          />
        </label>
      )}
      {formData.coachingDifficulties && (
        <label>
          Résolution des difficultés:
          <textarea
            name="resolutionDetails"
            value={formData.resolutionDetails}
            onChange={handleChange}
          />
        </label>
      )}
      <label>
        Appréciation générale:
        <textarea
          name="generalFeedback"
          value={formData.generalFeedback}
          onChange={handleChange}
        />
      </label>
      <label>
        Date de l'entretien:
        <input
          type="date"
          name="interviewDate"
          value={formData.interviewDate}
          onChange={handleChange}
        />
      </label>
      <label>
        Nom de l’administrateur:
        <input
          type="text"
          name="adminName"
          value={formData.adminName}
          onChange={handleChange}
        />
      </label>
      <label>
        Position de l’administrateur:
        <input
          type="text"
          name="adminPosition"
          value={formData.adminPosition}
          onChange={handleChange}
        />
      </label>
      <button type="submit">Générer la Fiche PDF</button>
    </form>
  );
}

export default FicheMaitreStage;
