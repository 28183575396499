import { BrowserRouter, Routes, Route } from "react-router-dom";

import { AuthProvider } from "./context/auth";
import Main from "./components/nav/Main";
// import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import PageNotFound from "./pages/404";
import MissionForm from "./components/MissionForm";
import SuiviStagiares from "./components/SuiviStagiares";
import SuiviChefEntreprise from "./components/SuiviChefEntreprise";
import FicheMaitreStage from "./components/FicheMaitreStage";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Main />
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}

          <Route path="/" element={<MissionForm />} />
          <Route path="/mission" element={<MissionForm />} />
          <Route path="/SuiviStagiares" element={<SuiviStagiares />} />

          <Route path="/FicheMaitreStage" element={<FicheMaitreStage />} />
          <Route
            path="/SuiviChefEntreprise"
            element={<SuiviChefEntreprise />}
          />

          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
